import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import EntityFormPage, { EntityForm } from '@entity/EntityForm/EntityForm';
import getGraphqlData from '@boilerplate/lib/getGraphqlData';
import { BaseFormInsertProps } from '@boilerplate/types/entity';
import { addEntityToRelatedDataOptions } from '@boilerplate/lib/relatedDataToOptions';
import Notistack from '@/lib/notistack';
import { catchGraphQlError } from '@/lib/catchError';

import TenantEntity from '@/entities/tenant';
  import UserTenantRoleEntity from '@/entities/userTenantRole';
  import ContentEntity from '@/entities/content';
  import AllowedApiHostnameEntity from '@/entities/allowedApiHostname';
  import MailTemplateEntity from '@/entities/mailTemplate';
  import MailLayoutEntity from '@/entities/mailLayout';
  import MissingContentEntity from '@/entities/missingContent';
  import AuthorizationCodeEntity from '@/entities/authorizationCode';
  import TenantUserEntity from '@/entities/tenantUser';

function TenantBaseInsertForm({ formOnly = false, onClose, Entity = TenantEntity, ...props }: BaseFormInsertProps<typeof TenantEntity.model>) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [createMutation, { loading }] = TenantEntity.model.useCreate();

  const Component = formOnly ? EntityForm : EntityFormPage;

  const relationsOptionsQuery = TenantEntity.model.useRelationsOptions({
    variables: {
    }
  });

  const relationsOptions = useMemo(
    () =>
      addEntityToRelatedDataOptions(relationsOptionsQuery, [
      ]),
    [relationsOptionsQuery]
  );

  const handleSubmit = (data) => {
    data.variables = getGraphqlData(Entity, data.variables, 'insert');

    createMutation(data)
      .then((result) => {
        Notistack.toast(t('crud:createdItem', { item: t('entities:tenant') }), { variant: 'success' });


        if (!formOnly) {
          navigate(-1);
        }

        return onClose?.(result.data?.createTenant);
      })
      .catch(catchGraphQlError);
  };

  if (relationsOptionsQuery.loading) {
    return <CircularProgress />;
  }

  return (
    <Component
      {...props}
      type="insert"
      Entity={Entity}
      loading={loading}
      handleSubmit={handleSubmit}
      relationsOptions={relationsOptions}
    />
  );
}

export default TenantBaseInsertForm;
